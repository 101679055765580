@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700;900&display=swap');
:root {
  --animate-delay: 100ms;
}
html {
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}
.block-scroll {overflow:hidden;}
/* div[id]{
  scroll-margin-top: 80px;
} */
.svg-icon svg {
  height: 1.25rem !important;
  width: 1.25rem !important;
}
.svg-icon.svg-2x svg {
  height: 1.8rem !important;
  width: 1.8rem !important;
}
.svg-icon.svg-3x svg {
  height: 2.75rem !important;
  width: 2.75rem !important;
}
.banner {
  height: auto;
}
@media (min-width: 768px) {
  .banner {
    height: calc(100vh - 106px);
  }
}
.wave-width {
  max-width: 10000px !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  
  opacity: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
select {
  display: block;
  width: 100%;
  padding: 0.75rem 3rem 0.75rem 1rem !important;
  -moz-padding-start: calc(1rem - 3px) !important;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid #E4E6EF;
  border-radius: 0.95rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
.list-nums ::before {
  display: table-cell;
  padding-right: 0.6em;
  content: counters(item, ".") ". ";
}
.r-text{
  font-size: 1.25em !important;
}
.container-works{
  display: flex;
  justify-content: center;
  width: 100%
}
@tailwind base;
@tailwind components;
@tailwind utilities;
